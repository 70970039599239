import React from 'react';
import './ProjectTile.scss';

export default class ProjectTile extends React.Component {
    
    render() {
        const {imgSrc, imgAlt, href, title}= this.props;
        
        return (            
            <div className="project-tile">                
                <div className="project-body">
                  <img 
                    src={imgSrc}
                    alt={imgAlt} />
                </div>
                <div className="project-title">
                  <a 
                    href={href} 
                    title={title}
                    target="_blank" 
                    rel="noopener noreferrer">{title}
                  </a>
                </div>              
            </div>
        )
    }
}