const data = {
    projects: [
        {
            title: "REST Countries API",
            image: {
                source: "images/countries_rest_api/screenshot_dark_theme.png",
                alt: "REST Countries API"
            },
            link: "https://rest-countries-api.armino-dev.now.sh",
            description: ""
        },
        {
            title: "EasyBank Landing",
            image: {
                source: "images/easy_bank_landing/screenshot_desktop.png",
                alt: "EasyBank Landing Page"
            },
            link: "https://easybank-landing.armino-dev.now.sh/",
            description: ""
        },
        {
            title: "jQuery Timed Dialog",
            image: {
                source: "https://armino-dev.github.io/extreme-cdn/images/jQuery%20Timed%20Dialog%20UI%20-%20Default%20functionality.png",
                alt: "jQuery timed dialog plugin"
            },
            link: "https://armino-dev.github.io/jquery-timed-dialog/demo/",
            description: ""
        },
        {
            title: "Tribute To Nikola Tesla",
            image: {
                source: "https://armino-dev.github.io/extreme-cdn/images/Tribute%20Page.png",
                alt: "Tribute to Nikola Tesla on Codepen"
            },
            link: "https://codepen.io/armino-dev/pen/BaygBpW",
            description: ""
        },
    ],
};

export default data;
