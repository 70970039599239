import React from 'react';
import ProjectTile from './Components/ProjectTile'
import './App.scss';
import data from './Data/data';
class App extends React.Component {
  
  render() {
    const projects = data.projects || [];
    return (
      <div className="App">
        <nav id="navbar" className="nav">
          <ul className="nav-list">
            <li><a href="#welcome-section" title="About">About</a></li>
            <li><a href="#projects" title="My projects">Work</a></li>
            <li><a href="#contact" title="Contact me">Contact</a></li>
          </ul>
        </nav>
        <div className="main">
          <section id="welcome-section">
            <h1>Hi, I am Armino,</h1>
            <p className="text-center">a software developer, or a full-stack developer as the corporate trend names it, but I like to call myself a builder of things.</p>
            <p className="text-center">My stack is <abbr>LAMP</abbr>, but it can also be <abbr>MERN</abbr> or <abbr>MEAN</abbr>. Let's not get stuck into specifics.</p>
            <p className="text-center">This web app is work in progress.</p>
          </section>

          <section id="projects">
            <h2 className="projects-section-header">Here are some of my projects</h2>
            <div className="container">
              {projects && ( <>
                {projects.map( (project, index) => {
                  return (
                    <ProjectTile
                      key={index}
                      imgSrc={project.image.source}
                      imgAlt={project.image.alt}
                      href={project.link}
                      title={project.title}
              />
                  )
                })}  
              </>
              )}              
            </div>
          </section>

          <section id="contact" className="contact-section">
            <h2 className="contact-section-header">Let's get in touch on ...</h2>
            <ul className="contact-list">
              <li><a id="profile-link" href="https://github.com/armino-dev" title="Get in touch on GitHub" target="_blank" rel="noopener noreferrer"><i className="fab fa-github"></i> GitHub</a></li>
              <li><a href="https://www.freecodecamp.org/fcc3bece262-e946-4ca9-aba2-f34d25d6823f" title="Get in touch on FreeCodeCamp" target="_blank" rel="noopener noreferrer"><i className="fab fa-free-code-camp"></i> FreeCodeCamp</a></li>
              <li><a href="https://www.linkedin.com/in/armino/" title="Get in touch on LinkedIn" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i> LinkedIn</a></li>
              <li><a href="https://stackoverflow.com/story/armino" title="Get in touch on StackOverflow" target="_blank" rel="noopener noreferrer"><i className="fab fa-stack-overflow"></i> StackOverflow</a></li>
              <li><a href="https://www.hackerrank.com/armino_popp" title="Get in touch on HackerRank" target="_blank" rel="noopener noreferrer"><i className="fab fa-hackerrank"></i> HackerRank</a></li>
            </ul>
          </section>
        </div>
        <footer>
          <p>Built with React. 
            Made with love, coffee and tobacco by Armino.</p>
        </footer>
      </div>
    );
  }
}

export default App;
